import React from 'react'
import { useModal } from 'react-modal-hook'
import UserIcon from 'components/UserIcon'
import Clickable from 'components/Clickable'
import UserProfile from 'components/UI/UserProfile'

interface UserLabelProps {
  id: number
  reference?: string
  enabled?: boolean
  internal?: boolean
  label?: string | React.ReactNode
  withIcon?: boolean
  className?: string
}

function UserLabel({
  id,
  reference,
  enabled = true,
  internal = true,
  label = '',
  withIcon = true,
  className = '',
}: UserLabelProps) {
  const [showProfileModal, hideProfileModal] = useModal(() => (
    <UserProfile
      id={id}
      onClose={hideProfileModal}
    />
  ), [id])

  const handleClick = () => {
    if (!id) return
    showProfileModal()
  }

  return (
    <Clickable
      data-test-reference={reference}
      className="flex items-center cursor-pointer"
      onClick={handleClick}
      title={label?.toString()}
    >
      {withIcon && (<UserIcon enabled={enabled} internal={internal} />)}
      <div className={`truncate pt-[3px] hover:font-bold ${withIcon ? 'ml-2' : ''} ${className}`}>
        {label}
      </div>
    </Clickable>
  )
}

export default UserLabel
