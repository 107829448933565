import React, { forwardRef } from 'react'

interface ClickableProps {
  onClick: () => void
  children: React.ReactNode
  className?: string
  title?: string
}

/**
 * The most simple clickable element
 */
const Clickable = forwardRef<HTMLDivElement, ClickableProps>(({ onClick, children, ...props }, ref) => (
  <div
    ref={ref}
    onClick={onClick}
    role="button"
    onKeyDown={e => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick()
      }
    }}
    tabIndex={0}
    {...props}
  >
    { children }
  </div>
))

export default Clickable
